<template>
    <div class="auth-container">
        <div class="auth-text">
            <div class="title">
                Цифровые
                <span>
                    <AlfaLogoIcon />
                    генты:</span
                >
                Секретная служба
            </div>
            <div class="text">Специальная программа подготовки агентов цифрового мышления Альфа-Банка</div>
        </div>
        <AlCard class="auth-form" key="auth">
            <div class="form-title">Вход в аккаунт</div>
            <b-form @submit="onSubmit">
                <b-form-group id="auth">
                    <b-form-input
                        name="login"
                        @change.native="onChange"
                        placeholder="Логин"
                        :state="$v.formAuth.login.$error ? false : null"
                    ></b-form-input>
                    <al-password-input
                        name="password"
                        @change.native="onChange"
                        placeholder="Пароль"
                        :state="$v.formAuth.password.$error ? false : null"
                    />
                    <div class="error-title" v-if="getFirstError || serverError">{{ errorText || serverError }}</div>
                    <b-button variant="primary" type="submit">Войти</b-button>
                </b-form-group>
            </b-form>
            <div class="register-container">
                <b-button variant="secondary" @click="switchForm">Пройти идентификацию </b-button>
                <al-bonus-badge :count="16" class="ml-4 text-subtitle-1" /><span class="text-primary">*</span>
            </div>
            <div class="forgot-password">
                <div>Забыли пароль?</div>
                <router-link :to="{ name: 'ForgotPassword' }" class="link">Восстановить</router-link>
            </div>
            <p class="legend">
                <span>*</span>Байты
                <bytes-icon />
                — это баллы, которые помогают вам подниматься в таблице лидеров.
            </p>
        </AlCard>
    </div>
</template>
<script>
import AlCard from '@/components/AlCard.vue';
import { required, sameAs, email, helpers } from 'vuelidate/lib/validators';
import AlBonusBadge from '@/components/AlBonusBadge';
import BytesIcon from '@/assets/images/icons/bytes.svg';
import AlPasswordInput from '@/components/AlPasswordInput';
import AlfaLogoIcon from '@/assets/images/icons/AlfaLogoIcon.svg'
const passwordMatch = helpers.regex('passwordMatch', /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/);

export default {
    name: 'Authorization',
    components: { AlPasswordInput, AlBonusBadge, AlCard, BytesIcon, AlfaLogoIcon },
    data: () => ({
        formAuth: {
            login: '',
            password: '',
        },
        serverError: '',
    }),
    validations: {
        formAuth: {
            login: {
                required,
            },
            password: {
                required,
            },
        },
    },
    computed: {
        getFirstError() {
            if (this.$v.$anyError) {
                return Object.keys(this.$v.formAuth).find(x => this.$v.formAuth[x].$error === true);
            }
        },
        errorText() {
            if (this.getFirstError === 'login') return 'Введите логин';
            if (this.getFirstError === 'password') return 'Введите пароль';
        },
    },
    methods: {
        onChange(e) {
            this.formAuth[e.target.name] = e.target.value;
        },
        async onSubmit(event) {
            event.preventDefault();
            this.$v.formAuth.$touch();
            if (this.$v.formAuth.$anyError) {
                return;
            }
            this.serverError = '';
            const loginInput = new FormData();
            loginInput.append('email', this.formAuth.login.trim().toLowerCase());
            loginInput.append('password', this.formAuth.password);
            await this.$store
                .dispatch('auth/authorization', loginInput)
                .then(() => {
                    let to_route = this.$route.query?.from;
                    this.$router.push(to_route ?? '/');
                })
                .catch(error => {
                    if (error?.response.status === 400) {
                        this.serverError = 'Неверный логин или пароль';
                    } else {
                        this.serverError = 'Что-то пошло не так';
                    }
                });
        },
        switchForm() {
            this.$router.push('/register');
            this.$v.$reset();
            this.formAuth = {
                login: '',
                password: '',
            };
        },
    },
};
</script>
<style lang="scss" scoped></style>
